exports.components = {
  "component---src-components-layout-blog-layout-js": () => import("./../../../src/components/Layout/BlogLayout.js" /* webpackChunkName: "component---src-components-layout-blog-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-qr-listok-js": () => import("./../../../src/pages/qr/listok.js" /* webpackChunkName: "component---src-pages-qr-listok-js" */),
  "component---src-pages-qr-menu-js": () => import("./../../../src/pages/qr/menu.js" /* webpackChunkName: "component---src-pages-qr-menu-js" */),
  "component---src-pages-qr-program-js": () => import("./../../../src/pages/qr/program.js" /* webpackChunkName: "component---src-pages-qr-program-js" */)
}

